import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { ModalContatoService } from './modal-contato.service';

@Component({
  selector: 'app-modal-contato',
  templateUrl: './modal-contato.component.html',
  styleUrls: ['./modal-contato.component.css']
})
export class ModalContatoComponent implements OnInit {

  constructor(private formBuilder: FormBuilder, public modalContatoService:ModalContatoService) { }

  @Input() openModal:boolean;
  @Output() closeModal = new EventEmitter<any>();
  
  public model: FormGroup;
  public isLoading:boolean;
  public maskPhone;

  ngOnInit() {
    this.isLoading = false;
    this.maskPhone = ['(', /[0-9]/, /\d/, ')', ' ', /\d/, /\d/, /\d/,/\d/, /\d/, '-', /\d/, /\d/, /\d/,/\d/];
    this.model = this.formBuilder.group({
      name: '',
      email:'',
      phone:'',
      empresa:'',
      mensagem:'',
    });
  }

  closeModalEvent(){
    this.closeModal.emit();
  }

  submit(){
    if (this.model.invalid)
      return;

      this.isLoading = true;

      this.modalContatoService.sendEmail(
        this.model.get('name').value,
        this.model.get('email').value,
        this.model.get('phone').value,
        this.model.get('empresa').value,
        this.model.get('mensagem').value
      ).subscribe(
        sucesso =>{
          this.isLoading = false;
          alert("Seu contato foi enviado com sucesso! Dentro de 24 horas entraremos em contato com você!");
          this.closeModal.emit();
        },
        erro =>{
          this.isLoading = false;
          alert("Erro, verifique sua conexão com a internet e tente novamente mais tarde.");
        }
      );
  }

}
