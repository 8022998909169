import { Component, OnInit, ElementRef, Renderer2, HostListener, ViewChild, Inject } from '@angular/core';
import { WINDOW, LOCAL_STORAGE } from '@ng-toolkit/universal'
import { MatSliderChange } from '@angular/material';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  constructor(private renderer: Renderer2, @Inject(WINDOW) private window:Window, @Inject(LOCAL_STORAGE) private localStorage: any) { }

  public modalIsOpen:boolean;

  @ViewChild("section1img1",  {static: true}) section1img1: ElementRef;
  @ViewChild("section1img2",  {static: true}) section1img2: ElementRef;
  @ViewChild("section1img3",  {static: true}) section1img3: ElementRef;
  @ViewChild("section1img4",  {static: true}) section1img4: ElementRef;
  @ViewChild("section1img5",  {static: true}) section1img5: ElementRef;  
  @ViewChild("section1img6",  {static: true}) section1img6: ElementRef;
  @ViewChild("section1p1",  {static: true}) section1p1: ElementRef;
  @ViewChild("section1p2",  {static: true}) section1p2: ElementRef;
  @ViewChild("section1p3",  {static: true}) section1p3: ElementRef;
  @ViewChild("section1p4",  {static: true}) section1p4: ElementRef;
  @ViewChild("section1p5",  {static: true}) section1p5: ElementRef;
  @ViewChild("section1p6",  {static: true}) section1p6: ElementRef;

  @ViewChild("section2img1",  {static: true}) section2img1: ElementRef;
  @ViewChild("section2img2",  {static: true}) section2img2: ElementRef;
  @ViewChild("section2img3",  {static: true}) section2img3: ElementRef;
  @ViewChild("section2imgArrow1",  {static: true}) section2imgArrow1: ElementRef;
  @ViewChild("section2imgArrow2",  {static: true}) section2imgArrow2: ElementRef;

  @ViewChild("section3title",  {static: true}) section3title: ElementRef;
  @ViewChild("section3p",  {static: true}) section3p: ElementRef;
  @ViewChild("section3img",  {static: true}) section3img: ElementRef;
  
  @ViewChild("section4title",  {static: true}) section4title: ElementRef;
  @ViewChild("section4p",  {static: true}) section4p: ElementRef;
  @ViewChild("section4",  {static: true}) section4: ElementRef;

  @ViewChild("section5title",  {static: true}) section5title: ElementRef;
  @ViewChild("section5p",  {static: true}) section5p: ElementRef;
  @ViewChild("section5img",  {static: true}) section5img: ElementRef;

  @ViewChild("section6img",  {static: true}) section6img: ElementRef;

  @ViewChild("section7title",  {static: true}) section7title: ElementRef;
  @ViewChild("section7p1",  {static: true}) section7p1: ElementRef;
  @ViewChild("section7p2",  {static: true}) section7p2: ElementRef;
  @ViewChild("section7p3",  {static: true}) section7p3: ElementRef;
  @ViewChild("section7img",  {static: true}) section7img: ElementRef;

  @ViewChild("section8title",  {static: true}) section8title: ElementRef;
  @ViewChild("section8img",  {static: true}) section8img: ElementRef;
  @ViewChild("section8p",  {static: true}) section8p: ElementRef;

  @ViewChild("section9Title",  {static: true}) section9Title: ElementRef;
  @ViewChild("section9plan",  {static: true}) section9plan: ElementRef;

  @ViewChild("section10",  {static: true}) section10: ElementRef;
  
  ngOnInit() {

  }

  @HostListener('window:scroll', [])
  onScroll() {
    this.setClassScrol(this.section1img1, "fadeInUp");
    this.setClassScrol(this.section1img2, "fadeInUp");
    this.setClassScrol(this.section1img3, "fadeInUp");
    this.setClassScrol(this.section1img4, "fadeInUp");
    this.setClassScrol(this.section1img5, "fadeInUp");
    this.setClassScrol(this.section1img6, "fadeInUp");
    this.setClassScrol(this.section1p1, "fadeIn");
    this.setClassScrol(this.section1p2, "fadeIn");
    this.setClassScrol(this.section1p3, "fadeIn");
    this.setClassScrol(this.section1p4, "fadeIn");
    this.setClassScrol(this.section1p5, "fadeIn");
    this.setClassScrol(this.section1p6, "fadeIn");

    this.setClassScrol(this.section2img1, "fadeInUp");
    this.setClassScrol(this.section2img2, "fadeInDown");
    this.setClassScrol(this.section2img3, "fadeInUp");
    this.setClassScrol(this.section2imgArrow1, "fadeInLeft");
    this.setClassScrol(this.section2imgArrow2, "fadeInLeft");

    this.setClassScrol(this.section3title, "fadeInLeft");
    this.setClassScrol(this.section3p, "fadeInLeft");
    this.setClassScrol(this.section3img, "fadeIn");

    this.setClassScrol(this.section4, "fadeInLeft");
    this.setClassScrol(this.section4title, "fadeInRight");
    this.setClassScrol(this.section4p, "fadeInRight");

    this.setClassScrol(this.section5title, "fadeInLeft");
    this.setClassScrol(this.section5p, "fadeInLeft");
    this.setClassScrol(this.section5img, "fadeInRight");

    this.setClassScrol(this.section6img, "fadeInRight");

    this.setClassScrol(this.section7title, "fadeInLeft");
    this.setClassScrol(this.section7p1, "fadeInLeft");
    this.setClassScrol(this.section7p2, "fadeInLeft");
    this.setClassScrol(this.section7p3, "fadeInLeft");
    this.setClassScrol(this.section7img, "fadeIn");

    this.setClassScrol(this.section8title, "fadeInRight");
    this.setClassScrol(this.section8img, "fadeInRight");
    this.setClassScrol(this.section8p, "fadeInRight");

    this.setClassScrol(this.section9Title, "fadeInDown");
    this.setClassScrol(this.section9plan, "fadeInUp");

    this.setClassScrol(this.section10, "fadeInRight");
  }

  setClassScrol(element:ElementRef, classe:string){
    
     if(element.nativeElement.getBoundingClientRect().top - this.window.innerHeight < -10 && !element.nativeElement.classList.contains("animated")){
      this.renderer.addClass(element.nativeElement, "animated");
      this.renderer.addClass(element.nativeElement, classe);
     }
  }

  openModal(){
    this.modalIsOpen = true;
  }

  closeModal(){
    this.modalIsOpen = false;
  }

  price:number = 55;
  priceDecimal:string = "50";
  cobranca:string = "75";
  precoPorCobranca:string = 'R$ 0,73 /cobrança';
  
  onInputChange(event: MatSliderChange) {
    let value:number = event.value;

    if (value == 1){
      this.price = 55;
      this.priceDecimal = "50";
      this.cobranca = "75";
      this.precoPorCobranca = 'R$ 0,73 /cobrança';
    }
    else if(value == 2){
      this.price = 110;
      this.priceDecimal = "00";
      this.cobranca = "150";
      this.precoPorCobranca = 'R$ 0,73 /cobrança';
    }
    else if(value == 3){
      this.price = 165;
      this.priceDecimal = "00";
      this.cobranca = "300";
      this.precoPorCobranca = 'R$ 0,55 /cobrança';
    }
    else if(value == 4){
      this.price = 220;
      this.priceDecimal = "00";
      this.cobranca = "500";
      this.precoPorCobranca = 'R$ 0,44 /cobrança';
    }
    else if(value == 5){
      this.price = 275;
      this.priceDecimal = "00";
      this.cobranca = "750";
      this.precoPorCobranca = 'R$ 0,37 /cobrança';
    }
    else if(value == 6){
      this.price = 330;
      this.priceDecimal = "00";
      this.cobranca =  "1000";
      this.precoPorCobranca = 'R$ 0,33 /cobrança';
    }
    else if(value == 7){
      this.price = 330;
      this.priceDecimal = "00";
      this.cobranca =  "Acima de 1000";
      this.precoPorCobranca = '+ R$0,22 por cobrança';
    }
  }

  scroll(el: HTMLElement) {
    el.scrollIntoView( {behavior: 'smooth'} );
  }
}

