import { Component, OnInit, HostListener, Inject, Output, EventEmitter } from '@angular/core';
import { WINDOW, LOCAL_STORAGE } from '@ng-toolkit/universal'

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  constructor(@Inject(WINDOW) private window:Window) { 
    this.setClassScroll = false;
  }

  public isMobile:boolean;
  @Output() clickVantagens = new EventEmitter();
  @Output() clickComoFunciona = new EventEmitter();
  @Output() clickPrecos = new EventEmitter();
  @Output() clickQueroUsar = new EventEmitter();

  ngOnInit() {
    this.isMobile = this.window.innerWidth <= 991;
  }

  setClassScroll:boolean;

  @HostListener('window:scroll', [])
  onScroll() {
    if(window.pageYOffset > 10)
      this.setClassScroll = true;
    else
      this.setClassScroll = false;
  }
  

}
