import { Injectable, } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';


@Injectable()
export class ModalContatoService {

  constructor(private client : HttpClient) { }

  sendEmail(name:string, email:string, phone:string, empresa:string, mensagem:string):Observable<any>{

    let customer = {
      email:email,
      recipient:"evandrobraz@pagamentorecorrente.com",
      name:name,
      phone:phone,
      companyName:empresa,
      message:mensagem,
    };

    //return this.client.post(environment.urlServiceNotification + "FormData", customer, {
    return this.client.post(environment.urlServiceNotification + "contatosite", customer, {      
      headers: new HttpHeaders({
        'Content-Type': 'application/json; charset=utf-8',
            'Accept-Language': 'pt-BR' 
        })
    })
  }
}
